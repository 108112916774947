<template>
    <div class="modal-backdrop">
        <section id="home" class="video-hero js-fullheight" v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')'}" style="height: 700px; background-size:cover; background-position: top center;" >
            <div class="overlay"></div>
            <!-- <a class="player" data-property="{videoURL:'https://www.youtube.com/watch?v=Mjjw19B7rMk',containment:'#home', showControls:false, autoPlay:true, loop:true, mute:true, startAt:0, opacity:1, quality:'default',optimizeDisplay:true}"></a> -->
            <div class="container">
                <div class="row js-fullheight justify-content-center d-flex align-items-center">
                    <div class="col-md-12">
                        <div class="text text-center">
                            <div class="icon">
                                <!-- <span class="flaticon-rose-outline-variant-with-vines-and-leaves"></span> -->
                                <span >
                                    <img class="wayang" src="assets/images/wayang.png" alt="icon">
                                </span>
                            </div>
                            <span class="subheading">The Engagement of</span>
                            <h1  v-if="client.hajat == 'pria'" >{{client.nama_pria}} &amp; {{client.nama_wanita}}</h1>
                            <h1  v-if="client.hajat == 'wanita'" >{{client.nama_wanita}} &amp; {{client.nama_pria}}</h1>
                            <p style="color: white; font-weight: bold; ">
                                Kepada Bapak/Ibu/Saudara/i
                            </p>
                            <h4 style="color: white; font-weight: 400; font-family: 'Great Vibes', cursive; font-size:7vw; ">
                                {{guest.nama}}
                            </h4>
                            <p style="color: white; font-weight: 600;" class="honor">
                                Tanpa mengurangi rasa hormat, Kami mengundang Anda untuk hadir diacara lamaran Kami
                            </p>
                            <!-- <div v-if="client.nama_venue === client.nama_venue_resepsi" id="timer" class="d-flex">
                            <div class="time" id="days"></div>
                            <div class="time pl-3" id="hours"></div>
                            <div class="time pl-3" id="minutes"></div>
                            <div class="time pl-3" id="seconds"></div>
                            </div> -->
                            <div class="wrap">
                                <button class="button" @click="close">Buka Undangan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    
</template>

<script>
  export default {
    name: 'Modal',
      props: ['client', 'guest'],
      methods: {
        close() {
          this.$emit('close');
        },
    },
  };
</script>