import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
// import About from '@/components/About.vue';
import PageNotFound from '../views/404.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: "/:slug",
        name: 'Home',
        component: Home,
    },
//   {
//     path: "/about",
//     name: "About",
//     component: About
//   },
    { 
        path: "*", 
        name: 'PageNotFound',
        component: PageNotFound 
    },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router